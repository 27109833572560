export const MONTH = 11;
export const YEAR = 2024;

export const CHRISTINE_TITLE = 'ADVENTSKALENDER 2024';

export const LOUISE_TITLE = 'Calendrier des loulous 2024';

export const DAYS_CONFIG_CHRISTINE = [
  {
    day: 1,
    picture: 'christine/Day1.jpg',
    message: 'Wooooow, es gibt einen neuen Adventskalender!!!',
  },
  {
    day: 2,
    picture: 'christine/Day2.jpg',
    message: 'War dieses Jahr ein Volltreffer? Bei so vielen Eiern, ja 😅',
  },
  {
    day: 3,
    picture: 'christine/Day3.jpg',
    message: 'Bald steht ja auch schon wieder ein Geburtstag an! 🎉',
  },
  {
    day: 4,
    picture: 'christine/Day4.jpg',
    message: 'Da ist unser kleines Wunder schon fast 3 Monate alt. Wie die Zeit vergeht...',
  },
  {
    day: 5,
    picture: 'christine/Day5.jpg',
    message: 'Schaukeln finde ich toll.',
  },
  {
    day: 6,
    picture: 'christine/Day6.jpg',
    message: 'Bald geht es wieder zusammen auf den Weihnachtsmarkt 🎅🏻',
  },
  {
    day: 7,
    picture: 'christine/Day7.jpg',
    message: 'Warum ist das Wasser so kalt, Papa??!!',
  },
  {
    day: 8,
    picture: 'christine/Day8.jpg',
    message: 'Ob es dieses Jahr eine weiße Weihnacht gibt? 🌨️',
  },
  {
    day: 9,
    picture: 'christine/Day9.jpg',
    message: 'Hallo Oma und Opa 👋',
  },
  {
    day: 10,
    jigsaw: {
      url: 'https://www.youtube.com/embed/L-MhqrKHjfs?si=DvDa7MOz8dvFQnJ-',
    },
  },
  {
    day: 11,
    picture: 'christine/Day11.jpg',
    message: 'Willkommen in der Familie ❤️',
  },
  {
    day: 12,
    picture: 'christine/Day12.jpg',
    message: 'Dieses Jahr gab es (viel) selbstgebrautes Bier. 🍺',
  },
  {
    day: 13,
    picture: 'christine/Day13.jpg',
    message: 'Ich bin schon so groß geworden! Schaut mal!',
  },
  {
    day: 14,
    picture: 'christine/Day14.jpg',
    message: 'Santé au Côté du Rhone! 🥂',
  },
  {
    day: 15,
    picture: 'christine/Day15.jpg',
    message: 'Ich bin ja für Winterschlaf von November bis März. Aber die anderen wollen immer raus. 🤷‍♀️',
  },
  {
    day: 16,
    picture: 'christine/Day16.jpg',
    message: 'Brrr... heute bleibe ich im Bett.',
  },
  {
    day: 17,
    picture: 'christine/Day17.jpg',
    message: 'Ist alles bereit für\'s Raclett? 🧀',
  },
  {
    day: 18,
    picture: 'christine/Day18.jpg',
    message: 'Ich schlafe schon einmal vor, damit ich an Weihnachten fit bin 💪',
  },
  {
    day: 19,
    picture: 'christine/Day19.jpg',
    message: 'Bald geht es zur Weihnachtsfeier mit der Familie 🎅🏻',
  },
  {
    day: 20,
    picture: 'christine/Day20.jpg',
    message: 'Ich bin schon so gespannt auf Weihnachten! 🎄',
  },
  {
    day: 21,
    picture: 'christine/Day21.jpg',
    message: 'So Sommerurlaub wäre jetzt ja auch nicht schlecht 🤔',
  },
  {
    day: 22,
    picture: 'christine/Day22.jpg',
    message: 'Hallo, ich bin Joshua René Cicewski, geboren am 22.09.2024 um 2:08 Uhr mit 49cm und 2570g 🐣',
  },
  {
    day: 23,
    picture: 'christine/Day23.jpg',
    message: 'Wir haben unser Geschenk für dieses Jahr schon bekommen. 🎁',
  },
  {
    day: 24,
    jigsaw: {
      url: 'https://www.youtube.com/embed/-MDcCm-F6Vs?si=eDSsyaQrbCnkEDtV',
    },
  },
];

// Continue with day 16
export const DAYS_CONFIG_LOUISE = [
  {
    day: 1,
    picture: 'louise/Day1.jpg',
    message: 'Cette année, Gaspard est devenu un grand garçon.',
  },
  {
    day: 2,
    picture: 'louise/Day2.jpg',
    message: 'On est fashion !!!',
  },
  {
    day: 3,
    jigsaw: {
      url: 'https://www.jigsawexplorer.com/online-jigsaw-puzzle-player.html?url=aHR0cHM6Ly9pbWFnZS50bWRiLm9yZy90L3Avb3JpZ2luYWwveTA5STF6OXprUm03S0JqYldjN1psdDNsRzJ3LmpwZw~~&nop=15',
      fallback: 'https://jigex.com/ujGgm',
    },
  },
  {
    day: 4,
    picture: 'louise/Day4.jpg',
    message: 'On aime faire des bêtises.',
  },
  {
    day: 5,
    jigsaw: {
      url: 'https://www.jigsawexplorer.com/online-jigsaw-puzzle-player.html?url=aHR0cHM6Ly9pLmViYXlpbWcuY29tL2ltYWdlcy9nL0xXUUFBT1N3OXlkZEhteU0vcy1sMTIwMC5qcGc~&nop=15',
      fallback: 'https://jigex.com/SdmcK',
    },
  },
  {
    day: 6,
    picture: 'louise/Day6.jpg',
    message: 'Automne 2024.',
  },
  {
    day: 7,
    jigsaw: {
      url: 'https://www.jigsawexplorer.com/online-jigsaw-puzzle-player.html?url=aHR0cHM6Ly93YWxscGFwZXJzLmNvbS9pbWFnZXMvaGQvd29vZHktYW5kLWJ1enotbGlnaHR5ZWFyLWRpc25leS00ay11bHRyYS13aWRlLTlxbHp1bXh5b3RkbXllem8uanBn&nop=15',
      fallback: 'https://jigex.com/e48N9',
    },
  },
  {
    day: 8,
    picture: 'louise/Day8.jpg',
    message: 'Tata et parrain au ski.',
  },
  {
    day: 9,
    jigsaw: {
      url: 'https://www.jigsawexplorer.com/online-jigsaw-puzzle-player.html?url=aHR0cHM6Ly9ydWttaW5pbTIuZmxpeGNhcnQuY29tL2ltYWdlLzg1MC8xMDAwL2t6enc1dTgwL3Bvc3Rlci9wL28vcC9tZWRpdW0tc25vdy13aGl0ZS1hbmQtdGhlLXNldmVuLWR3YXJmcy13YWxscGFwZXItb24tZmluZS1hcnQtb3JpZ2luYWwtaW1hZ2J2bnl0dG1menZuOS5qcGVnP3E9OTAmY3JvcD1mYWxzZQ~~&nop=15',
      fallback: 'https://jigex.com/cFSHd',
    },
  },
  {
    day: 10,
    picture: 'louise/Day10.jpg',
    message: 'Une petite chasse aux oeufs.',
  },
  {
    day: 11,
    jigsaw: {
      url: 'https://www.jigsawexplorer.com/online-jigsaw-puzzle-player.html?url=aHR0cHM6Ly9kYXRhLmZvdS1kZS1wdXp6bGUuY29tL25hdGhhbi4xNS9sZXMtYXZlbnR1cmVzLWRlLWxhLXBhdHBhdHJvdWlsbGUtMTAwLXBpZWNlcy0tcHV6emxlLjk1NTU0LTEuZnMuanBn&nop=15',
      fallback: 'https://jigex.com/ncj5u',
    },
  },
  {
    day: 12,
    picture: 'louise/Day12.jpg',
    message: 'Aie, ça fait mal.',
  },
  {
    day: 13,
    jigsaw: {
      url: 'https://www.jigsawexplorer.com/online-jigsaw-puzzle-player.html?url=aHR0cHM6Ly90aG9tYXNraW5rYWRlLmNvbS9jZG4vc2hvcC9jb2xsZWN0aW9ucy9jaW5kYW5fYi0xX2NmNDc5OTFkLWU0OGYtNGIzMi1hY2I3LTExMjA2ZWM0OGE4OC5qcGc_dj0xNjg5NjE5OTQ3JndpZHRoPTgwMA~~&nop=15',
      fallback: 'https://jigex.com/kPQ83',
    },
  },
  {
    day: 14,
    picture: 'louise/Day14.jpg',
    message: 'Papa est fort en vélo.',
  },
  {
    day: 15,
    jigsaw: {
      url: 'https://www.jigsawexplorer.com/online-jigsaw-puzzle-player.html?url=aHR0cHM6Ly9pbWcuZ29vZGZvbi5jb20vd2FsbHBhcGVyL2JpZy85LzI2L3RveS1zdG9yeS0yLXNoZXJpZmYtd29vZHktamVzc2llLWJ1enotbGlnaHR5ZWFyLndlYnA~&nop=15',
      fallback: 'https://jigex.com/ZuWM2',
    },
  },
  {
    day: 16,
    picture: 'louise/Day16.jpg',
    message: 'Encore une belle journée au parc !!!',
  },
  {
    day: 17,
    jigsaw: {
      url: 'https://www.jigsawexplorer.com/online-jigsaw-puzzle-player.html?url=aHR0cHM6Ly9pbWcuZnJlZXBpay5jb20vdmVjdGV1cnMtbGlicmUvbnVpdC1oaXZlci1lbm5laWdlZS1wZXJlLW5vZWwtY2hpZW5zLW1pZ25vbnNfMTMwOC04NzM2My5qcGc_dz0xMzgwJnQ9c3Q9MTcwMDUwNzc3N35leHA9MTcwMDUwODM3N35obWFjPTI0YTU5OTRiZDgyNzg3ZWY0MDZmZjAwYWNkMDM0N2FjZjJjODQxYjNjZjc3ZTgxM2U4YzJiZjBmYjNlYzM1MTk~&nop=12',
      fallback: 'https://jigex.com/iAFWq',
    },
  },
  {
    day: 18,
    picture: 'louise/Day18.jpg',
    message: 'Qui a gagné?',
  },
  {
    day: 19,
    jigsaw: {
      url: 'https://www.jigsawexplorer.com/online-jigsaw-puzzle-player.html?url=aHR0cHM6Ly9pbWctMzEuY2NtMi5uZXQvZVBoT1hweTdNbjJvc2FqS0laX3ZjMW5SWVdjPS8xMjQweC9zbWFydC83Mzg2YTUzMDcxZTM0NTQzODE2YTQxMDIyMzc1MzIxZi9jY21jbXMtaHVnby8xMDU1ODI4MS5qcGc~&nop=24',
      fallback: 'https://jigex.com/Pmf5D',
    },
  },
  {
    day: 20,
    picture: 'louise/Day20.JPG',
    message: "J'ai l'air toute sage...",
  },
  {
    day: 21,
    picture: 'louise/Day21.JPG',
    message: '... mais je fais quand même des bétises.',
  },
  {
    day: 22,
    jigsaw: {
      url: 'https://www.youtube.com/embed/LXpXN3hTJ3c?si=ffSlfEuZSXsZgOCl',
    },
  },
  {
    day: 23,
    picture: 'louise/Day23.png',
    message: 'Je suis allée en Allemagne pour la première fois.',
  },
  {
    day: 24,
    jigsaw: {
      url: 'https://www.youtube.com/embed/6Tfco_qHkrs?si=NtVIw3KHKYo5ubyV',
    },
  },
];

<template>
  <div class="row header">
    <div class="col-1 go-back">
      <div v-if="withBack" @click="goBack">
        <svg
          viewBox="0 0 16 16"
          class="bi bi-arrow-left-circle-fill"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1
            16 0zm-4.5.5a.5.5 0 0 0 0-1H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0
            0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5z"
          />
        </svg>
      </div>
    </div>
    <div class="col-10 title py-4">{{ title }}</div>
  </div>
</template>

<script>
import { CHRISTINE_TITLE, LOUISE_TITLE } from '../../constants';

export default {
  props: {
    withBack: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    title() {
      const receiver = process.env.VUE_APP_RECEIVER;
      return receiver === 'christine' ? CHRISTINE_TITLE : LOUISE_TITLE;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  align-items: center;
  text-align: center;
  .title {
    color: white;
    font-size: 40px;
    font-weight: bolder;
    justify-content: center;
    display: flex;
    text-shadow: 2px 2px black;
    @media only screen and (max-width: 600px) {
      font-size: 32px;
    }
  }

  .go-back {
    color: white;
    @media only screen and (max-width: 600px) {
      padding-top: 4rem;
      z-index: 1;
    }
  }

  .bi-arrow-left-circle-fill {
    height: 32px;
    width: 32px;
  }
}
</style>
